import { defaultBusinessHours,
        defaultServiceAvailability,
        closedServiceAvailability } from './businessHours'
import { toTwoDecimalPlaces }       from './numberConversion'
import { formatFullDate,
         isDateEmpty }              from './dateHelpers'


const today = new Date()
const tomorrow = new Date(today.setDate(today.getDate() + 1)).toISOString().slice(0, 10)

// returns true if an object has all null or empty properties. 
function hasAllNullProperties(obj) {
  for (let key in obj) {
      if (obj[key] !== null && obj[key] !== "") {
        return false
      }
  }
  return true
}


const availabilityDefaults = ({
  openTime:          900,
  closeTime:         1700,
  dayOfTheWeek:      null,
  startDate:         tomorrow,
  endDate:           tomorrow,
  startTime:         900,
  endTime:           1700,
  repeats:           false,
  bookingLimit: 0,
  repeat: {
    frequency: 'D',
    interval:  1,
    weekdays:  '',
    monthDay:  '1',
    monthType: 'D'
  },

  startTimeBlock:    1200,
  endTimeBlock:      1400,
  startDateBlock:    tomorrow,
  endDateBlock:      tomorrow,
  repeatsBlock:      false,
  repeatBlock: {
    frequency: 'D',
    interval:  1,
    weekdays:  '',
    monthDay:  '1',
    monthType: 'D'
  },
})


export const editLocationForm = (l) => {
  const timezone = l.timezoneIana === 'Europe/Paris' ? 'Europe/Madrid' : l.timezoneIana

  return {
    name:            l.name,
    website:         l.website,
    phone:           l.phone,
    email:           l.email,
    address:         l.address,
    timezoneName:    timezone,
    logo:            l.logo,
    previewImg:      l.imageUrl ? window.btoa(l.imageUrl) : '',
    id:              l.id
  }
}

export const editLocationAvailForm = (location) => ({
  businessHours:   location.businessHours,
  id:              location.id,
  ...availabilityDefaults
  
})

export const editLocationSettingsForm = (l, calendar) => ({
  id:              l.id,
  defaults:        l.defaults,
  settings:        l.settings,
  isCompanyScope:  !!l.settings.companyId,
  calendarId:      l.primaryCalendarId,
  maxGroupSize:    l.maxGroupSize,
  maxCapacity:     l.maxCapacity,
  bookingsPerSlot: calendar.bookingsPerSlot,
  primaryBusiness: l.primaryBusiness
})


export const editServiceForm = (service) => {
  var description
  if (service.description === service.name)
    description = ''
  else
    description = service.description

  return {
    id:                     service.id,
    name:                   service.name,
    type:                   service.type,
    duration:               service.duration,
    description:            service.description,
    bookingInterval:        service.bookingInterval,
    bookingLimit:           service.bookingLimit,
    serviceGroupId:         service.serviceGroupId,
    defaultService:         service.defaultService,
    durationSelect:         service.durationSelect,
    durationInterval:       service.durationInterval,
    durationMin:            service.durationMin,
    durationMax:            service.durationMax,
    padding:                service.padding,
    previewImgInput:        {},
    previewImg:             service.imageUrl ? window.btoa(service.imageUrl) : '',
    maxCapacity:            service.maxCapacity,
    maxGroupSize:           service.maxGroupSize,
    resource:               "",
    fees: {
      cancellationFeeAmount: service.cancellationFeeAmount > 0 ? toTwoDecimalPlaces(service.cancellationFeeAmount): '',
      feeAmount:             service.feeAmount > 0 ? toTwoDecimalPlaces(service.feeAmount) : ''
    }
  }
}

export const editServiceAvailForm = (service) => ({
  id:           service.id,
  availability: hasAllNullProperties(service.availability) ? closedServiceAvailability : service.availability,
  ...availabilityDefaults
})

export const editResourceForm = (resource) => {
  let description = resource.description ?? resource.description
  if (description === resource.name) {
    description = ""
  }

  let notificationType1 = false
  let notificationType2 = false
  if (resource.notificationType === 1)
    notificationType1 = true
  if (resource.notificationType === 2)
    notificationType2 = true
  if (resource.notificationType === 3) {
    notificationType1 = true
    notificationType2 = true
  }

  let calendarSync = 'none'
  let returnUrl = `${window.location.origin + window.location.pathname}`
  if (resource.googleCalendarAuthorized) {
    calendarSync = 'google'
    returnUrl = ''
  }
  else if (resource.outlookCalendarAuthorized) {
    calendarSync = 'outlook'
    returnUrl = ''
  }

  let calendarAvailability = false
  if (resource.options.calendarAvailability !== 0) {
    calendarAvailability = true
  }

  const address = hasAllNullProperties(resource.address) ? null : resource.address
  const timezone = resource.timezoneIana === 'Europe/Paris' ? 'Europe/Madrid' : resource.timezoneIana
  
  return {
    name:              resource.name,
    email:             resource.email,
    contact:           resource.contact,
    address,
    timezoneId:        timezone,
    description,
    notifications:     { notificationType1, notificationType2 },
    calendarSync,
    previewImgInput:   {},
    previewImg:        resource.imageUrl ? window.btoa(resource.imageUrl) : '',
    id: resource.id,
    calendarAvailability,
    calendarId:        resource.email,
    returnUrl,
  }
}

export const editResourceAvailabilityForm = (resource) => ({
  recurringAvailability: resource.recurringAvailability,
  availability:          resource.availability,
  id:                    resource.id,
  ...availabilityDefaults
})

export const editServiceAllocationForm = (allocation) => {
  let startDate = allocation.startDate
  let endDate = allocation.endDate
  let repeat = {}
  if (allocation.repeat) {
    repeat = allocation.repeat
  }

  if(startDate.includes('T')) {
    startDate = startDate.split('T')[0]
    endDate = endDate.split('T')[0]
  }
    return {
      startDate,
      endDate,
      startTime:    allocation.startTime,
      endTime:      allocation.endTime,
      bookingLimit: allocation.bookingLimit,
      repeats:      allocation.repeats,
      resourceId:   allocation.resourceId,
      serviceId:    allocation.serviceId,
      resourceName: allocation.resourceName,
      repeat,
      reason:       allocation.reason,
    }
}


export const editBlock = (block) => ({
  startDateBlock: block.startDate.split('T')[0],
  noEndDate: block.endDate[0] === '9', 
  endDateBlock:   block.endDate.split('T')[0],
  startTimeBlock: block.startTime,
  endTimeBlock:   block.endTime,
  reasonBlock:    block.reason,
  repeatBlock:    block.repeat,
  repeatsBlock:   block.repeats,
})


export const appointmentDisplay = appointment => {
  if (appointment.groupSize === 0) {
    appointment.groupSize = ''
  }
  
  return [
    {
      Id:                 appointment.id,
      Status:             appointment.status,
      "Date/Time":        formatFullDate(appointment.startDateTime), 
      Service:            appointment.serviceName,
      Duration:           appointment.duration + ' minutes',
      "Group Size":       appointment.groupSize,
      Location:           appointment.location,
      Resource:           appointment.resourceName,
      Email:              appointment.email,
      Phone:              appointment.phone, 
      "Customer Message": appointment.customerMessage, 
      notes:              appointment.notes,
      "Confirmation #":   appointment.confirmationNumber,
      Confirmed:          appointment.confirmed, 
      "Online Booking":   appointment.onlineBooking,
      "Booked By":        appointment.bookedBy,
      "Booked On":        appointment.createDate, 
    },
    {
      serviceId:                 appointment.serviceId,
      resourceId:                appointment.resourceId,
      "Timezone booked":         appointment.timezone,
      "email Confirmation Sent": isDateEmpty(appointment.emailConfirmationSent),
      "email Reminder Sent":     isDateEmpty(appointment.emailReminderSent),
      "SMS Confirmation Sent":   isDateEmpty(appointment.smsConfirmationSent),
      "sms reminder Sent":       isDateEmpty(appointment.smsReminderSent),
      "last modified on":        appointment.lastModifiedOn
    }
  ]
}

export const editCustomerForm = (customer) => ({
  email:            customer.email , //
  name:             customer.name , //
  emailInfo:        customer.emailInfo , // do we have consent Customer permission to send Confirmations and Reminders
  emailPromotion:   customer.emailPromotion , // do we have consent Customer permission to send Promotions and Special Offers
  contact: {
    homePhone:        customer.contact.homePhone ,
    mobilePhone:      customer.contact.mobilePhone ,
    businessPhone:    customer.contact.businessPhone ,
    businessPhoneExt: customer.contact.businessPhoneExt ,
  },
  address: {
    addressLine1: customer.address.addressLine1 ,
    addressLine2: customer.address.addressLine2 ,
    city:         customer.address.city ,
    state:        customer.address.state ,
    country:      customer.address.country ,
    postalCode:   customer.address.postalCode
  },
})


export const editCompanyForm = company => ({
  addressLine1: company.addressLine1,
  addressLine2: company.addressLine2,
  city:         company.city,
  state:        company.state,
  country:      company.country,
  postalCode:   company.postalCode,
  timezoneName: company.timezoneName,
  name:         company.name,
  email:        company.email,
  website:      company.website,

  reminderWebhookUrl:   company.reminderWebhookUrl,
  resourceWebhookUrl:   company.resourceWebhookUrl,
  bookingWebhookUrl:    company.bookingWebhookUrl,
  customerWebhookUrl:   company.customerWebhookUrl,
  webhookSignatureHash: company.webhookSignatureHash,

  disableEmailAndSmsNotifications: company.disableEmailAndSmsNotifications,
})
import React from 'react'

import { times, bookingsPerDay } from './businessHours'
import { renderCheckbox, renderField }           from './renderField'
import { renderDropdownField }   from './renderDropdownField' 

export const dayWeekMonthSwitch = (frequency, interval) => {
  let repeatFreq = 'Days'

  switch (frequency) {
    case 'D':
      repeatFreq =  'Days'
      break
  
    case 'W':
      repeatFreq =  'Weeks'
      break
  
    case 'M':
      repeatFreq =  'Months'
      break
  
    default:
      break
  }

  if (interval < 2)
    repeatFreq = repeatFreq.slice(0, -1)

  return repeatFreq
}


export const eventFields =  [
    {
      name: 'reason',
      label: 'Allocation Name',
      component: renderField,
      type: 'text',
      placeholder: 'Allocation Name',
    },
    {
      name:      "bookingLimit",
      label:     "Booking limit",
      component: renderDropdownField,
      options:   bookingsPerDay(),
    },
    {
      type:      "date",
      name:      "startDate",
      label:     "Starting",
      className: 'datepicker',
      customClass: 'fullWidth',
      component: renderField,
    },
    {
      name:      "startTime",
      component: renderDropdownField,
      label:     "From",
      options:   times.slice(0, -1).map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    },
    {
      name:      "endTime",
      component: renderDropdownField,
      label:     "To",
      options:   times.slice(1).map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    },
    {
      name: 'repeats' ,
      type: 'checkbox' ,
      customClass: 'split',
      label: 'Repeat' ,
      defaultValue: 'false',
      component: renderCheckbox
    },
    {
      type:      "date",
      name:      "endDate",
      label:     "Ending",
      className: "datepicker",
      component: renderField,
      dynamicVisibility: [
        {
          trigger: 'repeats',
          match: true
        }
      ],
    },
  ]

  export const eventFieldsResource =  [
    {
      name: 'reason',
      label: 'Allocation Name',
      component: renderField,
      type: 'text',
      customClass: 'fullWidth',
      placeholder: 'Allocation Name',
    },
    {
      type:      "date",
      name:      "startDate",
      label:     "Starting",
      className: 'datepicker',
      customClass: 'fullWidth',
      component: renderField,
    },
    {
      name:      "startTime",
      component: renderDropdownField,
      label:     "From",
      options:   times.slice(0, -1).map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    },
    {
      name:      "endTime",
      component: renderDropdownField,
      label:     "To",
      options:   times.slice(1).map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    },
    {
      name: 'repeats' ,
      type: 'checkbox' ,
      customClass: 'split',
      label: 'Repeat' ,
      defaultValue: 'false',
      component: renderCheckbox
    },
    {
      type:      "date",
      name:      "endDate",
      label:     "Ending",
      className: "datepicker",
      component: renderField,
      dynamicVisibility: [
        {
          trigger: 'repeats',
          match: true
        }
      ],
    },
  ]

  export const resourceAllocationFields =  [
    {
      type:      "date",
      name:      "startDate",
      label:     "Starting",
      className: 'datepicker',
      component: renderField,
    },
    {
      type:      "date",
      name:      "endDate",
      label:     "Ending",
      className: "datepicker",
      component: renderField,
    },
    {
      name:      "startTime",
      component: renderDropdownField,
      label:     "At",
      options:   times.map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    },
    {
      name:      "endTime",
      component: renderDropdownField,
      label:     "Until",
      options:   times.map(time => 
        ({
          label: `${time.time} ${time.meridiem}`, 
          value: time.value
        })
      )
    }
  ]

export const monthDayOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
]
import React       from 'react'
import { connect } from 'react-redux'

import { detectEnv, SBOX, PROD } from '../../../utils/detectEnv'

import './APIDocs.css'


const APIDocs = ({ referenceApiUrl }) => {
  let sbox = 'sandbox-'
  if (detectEnv() !== SBOX)
    sbox = ''

  const readmeReferenceApiUrl = referenceApiUrl?.referenceApiUrl
  const readmeReference = "https://docs.onsched.com/reference"
  const readme = "https://docs.onsched.com/docs"

  return (
    <div className="APIDocs">
      {
        detectEnv() !== PROD &&
        <div className='APIDocsLink'>
          <h5>Try out the API: </h5>
          <a target="_blank" href={readmeReferenceApiUrl}>{readmeReference}</a>
          <p>Take the API for a test drive. Follow this link and begin making API requests immediately!</p>
        </div>
      }
      <div className='APIDocsLink'>
        <h5>Learn more about the API: </h5>
        <a target="_blank" href={readme}>{readme}</a>
        <p>Comprehensive guides and documentation to help you start working with the OnSched API as quickly as possible,</p>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  referenceApiUrl: state.referenceApiUrl.referenceApiUrl
})

export default connect(mapStateToProps, {})(APIDocs)